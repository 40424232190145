import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import SubmitButton from '../Common/buttons/SubmitButton';
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ clientSecret, handleSubmitFinish }) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const isSetupIntent = clientSecret.includes('seti_'); // Check if it's a SetupIntent
    const isPaymentIntent = clientSecret.includes('pi_'); // Check if it's a PaymentIntent

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!stripe || !elements) {
            return;
        }

        // Trigger the form submission using elements.submit()
        const submitResult = await elements.submit();

        if (submitResult.error) {
            setIsLoading(false);
            setErrorMessage(submitResult.error.message);
            return;
        }

        let result;

        // Confirm SetupIntent or PaymentIntent based on the clientSecret
        if (isSetupIntent) {
            result = await stripe.confirmSetup({
                elements,
                clientSecret,
                redirect: "if_required"
            });
        } else if (isPaymentIntent) {
            result = await stripe.confirmPayment({
                elements,
                clientSecret,
                redirect: "if_required"
            });
        }

        if (result.error) {
            setIsLoading(false);
            setErrorMessage(result.error.message);
        } else {
            setIsLoading(false);
            // Handle result (either setupIntent or paymentIntent)
            handleSubmitFinish(undefined, result.setupIntent || result.paymentIntent, "stripe");
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <AddressElement options={{ mode: 'billing' }} /> 
            <SubmitButton
                className={"btn btn-primary checkout_cnt_btn"}
                isSubmitting={!stripe}
                text={"submit"}
                isLoading={isLoading}
            />
            {errorMessage && <div>{t(errorMessage)}</div>}
        </form>
    );
};

export default CheckoutForm;
