import React, { useState } from "react";
import { ethers } from "ethers";

const ConnectWalletButton: React.FC = () => {
  const [account, setAccount] = useState<string | null>(null);

  /* Connects to Wallet */
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        /* Store this account address to the users account in the db */
        const accounts = await provider.send("eth_requestAccounts", []);
        setAccount(accounts[0]);
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    } else {
      alert("MetaMask is not installed. Please install MetaMask to use this feature.");
    }
  };

  /* Disconnects Wallet */
  const disconnectWallet = () => {
    setAccount(null);
  };
  
  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div style={{textAlign: 'center'}}>
      <button
        onClick={connectWallet}
        style={buttonStyle}
      >
        {account ? `Connected: ${account.slice(0, 6)}...` : "Connect Wallet"}
      </button>
    </div>
  );
};

export default ConnectWalletButton;
