import React, { useEffect, useState } from "react";
import ReplicaRegisterForm from "../../components/Replica/ReplicaRegisterForm";
import ReplicaRegisterModal from "../../components/Common/modals/ReplicaRegisterModal";
import PartialAmntModal from "../../components/Common/modals/PartialAmntModal";
import anime from "animejs/lib/anime.es.js";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";

const ReplicaRegisterLayout = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showPartialAmntModal, setShowPartialAmntModal] = useState(false);
  const [activeStep, setActiveStep] = useState();
  const { t } = useTranslation();
  const [regSteps, setregSteps] = useState([])

  const installmentPlanText = `
  <h3>Installment Plan</h3>
  <p>The installment plan is 40% of the plan upon joining, another 30% of the plan after 30 days, and the remaining 30% after 60 days.</p>
  <p>Please note there is a $5 additional processing fee for each installment payment after the first payment. So if your second installment payment is $300, then you would pay an additional $5 processing fee, so the total amount for the second payment would be $305.</p>
`;
  // ----------------------------------------- API --------------------------------------------
  const registerFields1 = ApiHook.CallReplicaRegisterFields();
  useEffect(() => {
    if( registerFields1?.data?.data?.regPack === null && registerFields1?.data?.data?.regFee === 0 ) {
      setActiveStep(2);
    } else {
      setActiveStep(1);
    }
    setregSteps(registerFields1?.data?.data?.registrationSteps)
  },[registerFields1?.data?.data?.regPack])

  if (registerFields1.isFetching) {
    return (
      <div className="theme-loader">
        <div className="spinner">
          <div className="spinner-bounce one"></div>
          <div className="spinner-bounce two"></div>
          <div className="spinner-bounce three"></div>
        </div>
      </div>
    );
  }

  const handleToggleRegisterModal = () => {
    setShowRegisterModal(!showRegisterModal);
  };
  const handleTogglePartialAmntModal = () => {
    setShowPartialAmntModal(!showPartialAmntModal);
  };


  const animateStepTransition = (nextStep) => {
    const containerElement = document.querySelector("#animation");
    const nextStepElement = document.querySelector(
      `.register-left-cnt-row:nth-child(${nextStep + 1})`
    );

    // Set initial position of the next step
    anime.set(containerElement, { opacity: 0 });
    anime.set(nextStepElement, { translateY: "100%" });

    // Animate the next step in
    anime({
      targets: containerElement,
      opacity: [0, 1],
      duration: 1000,
      easing: "easeInOutQuad",
    });
    anime({
      targets: nextStepElement,
      translateY: "0%",
      duration: 500,
      easing: "easeInOutQuad",
    });
  };

  return (
    <>
      <div className="container ">
        <div className="register_row align-items-center justify-content-center">
          <div className="main-regsiter-left-section">
            <div className="register-left-cnt-row opacity-1">
              <h2>{t("registerNow")}</h2>
            </div>
            {regSteps?.map(
              (step, index) => (
                <div
                  key={index}
                  className={`register-left-cnt-row ${(registerFields1?.data?.data?.regFee === 0 && registerFields1?.data?.data?.regPack === null) ? (step.step === activeStep - 1 ? "active" : ""): (step.step === activeStep ? "active" : "")}`}
                >
                  <span>{index+1}</span> {t(`${step.label}`)}
                </div>
              )
            )}
          </div>
          <ReplicaRegisterForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleToggleRegisterModal={handleToggleRegisterModal}
            handleTogglePartialAmntModal={handleTogglePartialAmntModal}
            animateStepTransition={animateStepTransition}
            data={registerFields1?.data?.data}
            setregSteps={setregSteps}
            regSteps={regSteps}
          />
        </div>
      </div>
      <ReplicaRegisterModal
        show={showRegisterModal}
        handleClose={handleToggleRegisterModal}
        data={
          registerFields1?.data?.data?.termsAndCondition?.termsAndConditions
        }
      />
      <PartialAmntModal
          show={showPartialAmntModal}
          handleClose={handleTogglePartialAmntModal}
          data={installmentPlanText}
        />
    </>
  );
};

export default ReplicaRegisterLayout;
