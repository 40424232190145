import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { formatDate } from "../../utils/formateDate";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useReactToPrint } from "react-to-print";

const RegisterComplete = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const userSelectedCurrency = useSelector(
    (state) => state?.user?.selectedCurrency
  );
  const selectedLanguage = useSelector(
    (state) => state?.user?.selectedLanguage
  );
  const [successMessageShow, setSuccessMessageShow] = useState(true);
  const params = useParams();
  const username = params?.username;
  const preview = ApiHook.CallLetterPreview(username);
  const user = preview?.data?.userData;
  const companyData = preview?.data?.companyData;
  const packageData = preview?.data?.productData;
  const welcomeContent = preview?.data?.welcomeLetter;
  const selectedLanguageId = selectedLanguage?.id;
  const selectedWelcomeContent = welcomeContent?.find(
    (item) => item.id === selectedLanguageId
  );
  const welcomeContentHtml = selectedWelcomeContent?.content || "";
  const date = preview?.data?.date;
  const closeAlert = () => {
    setSuccessMessageShow(false);
  };
  const companyProfile = useSelector(
    (state) => state?.dashboard?.appLayout?.companyProfile
  );
  // Use useEffect to automatically call closeAlert after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      closeAlert();
    }, 3000);

    // Clear the timer when the component unmounts or when successMessageShow changes to false
    return () => {
      clearTimeout(timer);
    };
  }, [successMessageShow]);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  return (
    <>
      <div className="page_head_top">{t("registrationComplete")}</div>
      {successMessageShow && (
        <div
          className="alertNotification"
          style={{
            width: "100%",
            border: "1px solid #A377FF",
            background: "#D4CBFF",
            display: "inline-block",
            color: "#8349ff",
            padding: "8px 15px 8px 15px",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <p style={{ margin: "0" }}>{t("regCompleted")}</p>
          <div className="closeBtnIcon" onClick={closeAlert}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}
      <div className="ewallet_table_section" ref={tableRef}>
        <div className="ewallet_table_section_cnt">
          <div
            className="printBtnsec"
            style={{ textAlign: "end", marginBottom: "5px" }}
          >
            <button
              type="button"
              className="btn print-button"
              style={{ backgroundColor: "#954cea", color: "white" }}
              onClick={handlePrint}
            >
              {t("print")}
            </button>
          </div>
          <div className="table-responsive min-hieght-table">
            <div
              className="cmpnyAddressname"
              style={{
                textAlign: "end",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <img
                  src={companyData?.logo ?? "/images/logo_user.png"}
                  alt=""
                />
              </div>
              <div>
                <p style={{ marginBottom: "5px" }}>{companyData?.name}</p>
                <p style={{ marginBottom: "0" }}>{companyData?.address}</p>
              </div>
            </div>
            {packageData?.name ? (
              // If packageData.name is available, show the coverage details section
              <div
                style={{
                  backgroundColor: "#eee",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <h1
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    color: "#333",
                  }}
                >
                  Coverage Purchase Confirmation
                </h1>

                <span>
                  Welcome to One Llama Mutual! By joining forces with neighbors
                  and friends, the collective capital power of the group will
                  provide the Collision & Comprehensive Coverage you need to
                  protect your cars! The experienced best-in-class repair
                  network and trained experts at One Llama will ensure your car
                  is taken care of if ever there is an incident that damages
                  your car. And all the while your capital remains your capital,
                  so if it isn't used up by an accident event involving your
                  car, or the collective accidents supported by the group, then
                  you get some of that capital back! You should have received a
                  password reset notice in your inbox-- that will enable you to
                  set a secure password and then enter your One Llama Mutual
                  Member account. Once in the account you'll be able to manage
                  your cars and coverage choices, add authorized drivers, as
                  well as see your capital balance.
                </span>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        Mutuality Membership Coverage:
                      </td>
                      <td>6mo Coverage:</td>
                      <td style={{ textAlign: "right" }}>
                        {userSelectedCurrency?.symbolLeft}{" "}
                        {CurrencyConverter(
                          preview?.data?.totalAmount,
                          conversionFactor
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <table className="striped">
                <tbody>
                  <tr>
                    <td>{t("username")}</td>
                    <td>{user?.username}</td>
                  </tr>
                  <tr>
                    <td>{t("full Name")}</td>
                    <td>{user?.fullName}</td>
                  </tr>
                  <tr>
                    <td>{t("sponsor")}</td>
                    <td>{user?.sponsorName}</td>
                  </tr>
                  <tr>
                    <td>Plan</td>
                    <td>
                      {packageData?.name ? packageData.name : "Affiliate"}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("totalAmount")}</td>
                    <td>
                      {userSelectedCurrency?.symbolLeft}{" "}
                      {CurrencyConverter(
                        preview?.data?.totalAmount,
                        conversionFactor
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <div className="container-fluid">
            <div
              className="regardssec"
              style={{
                display: "inline-block",
                width: "100%",
                marginTop: "10px",
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterComplete;
