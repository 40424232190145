import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import SubmitButton from '../Common/buttons/SubmitButton';
import { ApiHook } from '../../hooks/apiHook';
import axios from 'axios';
import { toast } from 'react-toastify';


function QuoteModal({ showModal, close, t, fields, formValues, setshowQuoteModal, setrequestQuote }) {
    const {
        register,
        setValue,
        setError,
        trigger,
        watch,
        getValues,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const requestQuote = ApiHook.CallquoteRequest()
    const [vehicleInfo, setVehicleInfo] = useState(null);
    const submitHandler = async (data) => {
        const isValid = await trigger()
        if (isValid) {
            data["email"] = formValues.email
            const response = await requestQuote.mutateAsync(data)
            if (response.status) {
                reset()
                setshowQuoteModal(false)
                setrequestQuote(true)
            } else {
                toast.error(t(response?.description))
            }
        }
    }

    const changeHandler = async (e, type) => {

        try {
            if (type === "vin_numb") {
                const vin = e.target.value
                const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=json`);
                console.log("==response=====", response.data)
                const results = response.data.Results;
                const year = results.find(item => item.Variable === 'Model Year')?.Value;
                const model = results.find(item => item.Variable === 'Model')?.Value;
                const make = results.find((item) => item.Variable === "Make")?.Value;
                setValue('vehicle_model', model, { shouldValidate: true })
                setValue('vehicle_year', year, { shouldValidate: true })
                setValue("make", make, { shouldValidate: true });  // Using "make"
  
                setVehicleInfo({ year, model, make });
            }
        } catch (error) {
            console.log("===errorvin=====", error)
        }

    }
    console.log("====vehicleinfo", vehicleInfo)
    return (
        <Modal
            show={showModal}
            onHide={close}
            className='quote-modal'
            dialogClassName="custom-modal-dialog"
            backdrop="static"

            centered

        >
            <Modal.Header>{t('quote_request')}</Modal.Header>
            <Modal.Body style={{ padding: "30px" }}>
                <div className="row align-items-center">
                    {fields &&
                        fields?.map((item, index) => {
                            return (
                                <div className="col-md-6" key={index}>
                                    <div className="modal_change_password_right_cnt" style={{ padding: '0' }}>
                                        <div className="form-group mb-2">
                                            <Form.Label>{t(item.code)}</Form.Label>
                                            {
                                                item.type === "text" ?
                                                    <Form.Group>
                                                        <Form.Control
                                                            id={item.code}
                                                            type="text"
                                                            disabled={!item.editable}
                                                            // placeholder={item.code === "driver_license_number" ? "DL-1420110012345" : item.code === "vin_numb" ? "1HGCM82633A123456" : ''}
                                                            {...register(item.code, {
                                                                required: {
                                                                    value: item.required,
                                                                    message: t("this_field_is_required"),
                                                                },
                                                                pattern: item.code === "vin_numb" ? {
                                                                    value: /^[A-HJ-NPR-Z0-9]{17}$/,
                                                                    message: t('invalid_vin_number')
                                                                } : undefined,
                                                            })}
                                                            onChangeCapture={(e) => changeHandler(e, item.code)}
                                                            isInvalid={!!errors[item.code]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[item.code]?.message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    : item.type === 'dropdown' ?
                                                        <Form.Group>
                                                            <Form.Select
                                                                {...register(item.code, {
                                                                    required: {
                                                                        value: item.required,
                                                                        message: t("this_field_is_required"),
                                                                    },
                                                                })}
                                                                aria-label="Default select example"
                                                                isInvalid={!!errors[item.code]}
                                                            >
                                                                <option value="">Select</option>
                                                                {
                                                                    item?.options?.map((option, idx) => (
                                                                        <option value={option.id} key={idx}>{option.value}</option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors[item.code]?.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={close}
                        >
                            {t('close')}
                        </button>
                        <SubmitButton
                            className="btn btn-primary"
                            text={t('submit')}
                            click={handleSubmit(submitHandler)}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default QuoteModal